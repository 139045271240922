
var homepage = {
    cur_mobile_slide: 0,
    slide_length: $('.mobile-slider-item').length,
    init: function () {
        homepage.initSlider();
        homepage.initCarousel();
        $(window).load(function () {
        });
        $(window).resize(function () {
            homepage.repositionSliderElements();
        });
    },
    initCarousel: function () {
        $("#exposed-products").owlCarousel({
            itemsDesktop: [5000, 1],
            itemsDesktopSmall: false,
            itemsTablet: false,
            itemsTabletSmall: false,
            itemsMobile: false,
            navigation: true,
            pagination: false
        });
    },
    repositionSliderElements: function () {
        var h2_height = $('.in-slide-content.visible h2').innerHeight();
        var slider_height = $('.in-slide-content.visible').innerHeight() + 20;
        var bullet_height = $('.pager-container').innerHeight();
        if (app.win_width > 1200) {
            $('.slider').height(slider_height);
            $('.pager-container').css('margin-top', ((slider_height - bullet_height) / 2) + 'px');
        } else {
            $('.slider').height(slider_height + 50);
            $('.pager-container').css('margin-top', -320 + 'px');
        }
        $('.in-slide-content h2').css('margin-top', ((slider_height - h2_height) / 2) + 'px');
    },
    initSlider: function () {
        var pager, timeout;
        if (typeof only_one !== 'undefined') {
            timeout = 99999999999999;
            $('.pager-container').hide();
        } else {
            timeout = 10000;
        }
        pager = '#pager';
        $('#maximage').maximage({
            cycleOptions: {
                fx: 'fade',
                speed: 1000, // Set the speed for CSS transitions in jQuery.maximage.css (lines 30 - 33)
                timeout: timeout,
                pause: 1,
                pager: pager,
                pagerAnchorBuilder: function paginate(idx, el) {
                    return '<a class="' + idx + '" href="#" >&bull;</a>';
                },
                before: function () {
                    $('.in-slide-content').removeClass('visible');
                },
                after: function (ob, cur) {
                    $(cur).find('.in-slide-content').addClass('visible');
                    homepage.repositionSliderElements();
                }
            },
            fillElement: '#top-content .slider-container',
            backgroundSize: 'contain',
            onFirstImageLoaded: function () {
                $('#cycle-loader').hide();
                homepage.repositionSliderElements();
            }
        });
        $('.next-slide').click(function () {
            var active = $('#pager a.activeSlide');
            if (active.is(':last-child')) {
                $('#pager a:first-child').click();
            } else {
                active.next().click();
            }
            return false;
        });
        // Mobile
        $('.next-mobile-slide').click(function () {
            if (homepage.cur_mobile_slide + 1 === homepage.slide_length) {
                homepage.cur_mobile_slide = 0;
            } else {
                homepage.cur_mobile_slide++;
            }
            $('.mobile-slider-item:visible').fadeOut('fast', function () {
                $('.mobile-slider-item').eq(homepage.cur_mobile_slide).fadeIn();
            });
            return false;
        });
    },
    onAfter: function (currSlideElement, nextSlideElement, options, forwardFlag) {
        if ($(nextSlideElement).find('.in-slide-content').hasClass('white')) {
            $('header').addClass('white');
            $('.selectpicker').selectpicker('render');
        } else {
            $('header').removeClass('white');
            $('.selectpicker').selectpicker('render');
        }
    }
};
